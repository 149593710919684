const routes = [{
    path: '/agri-marketing/cotton/configuration',
    name: 'agri-marketing.cotton_configuration',
    component: () =>
        import('@/layouts/AgriMarketingLayout.vue'),
    meta: { auth: true },
    children: [{
            path: 'region',
            name: 'region',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/region/List.vue')
        },
        {
            path: 'zone',
            name: 'zone',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/zone-cotton/List.vue')
        },
        {
            path: 'hat',
            name: 'hat',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/hatt/List.vue')
        },
        {
            path: 'cotton-variety',
            name: 'cotton_variety',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/cotton-variety/List.vue')
        },
        {
            path: 'cotton-name',
            name: 'cotton_name',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/cotton-name/List.vue')
        },
        {
            path: 'season',
            name: 'season',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/season/List.vue')
        },
        {
            path: 'unit',
            name: 'unit',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/unit/List.vue')
        },
        {
            path: 'component-dashboard',
            name: 'component_dashboard',
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/component-dashboard/index.vue')
        },
        {
            path: 'report-header',
            name: 'report-header',
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/report-heading/List.vue')
        }
    ]
}]

export default routes
