const childRoutes = (prop) => [
  {
    path: 'nominated-organization-form',
    name: prop + 'nominated_organization_form',
    meta: { auth: true },
    component: () => import('../pages/nominated-organization/Form.vue')
  },
  {
    path: 'nominated-recomend-form',
    name: prop + 'nominated_recomend_form',
    meta: { auth: true },
    component: () => import('../pages/nominated-organization-recomend/Form.vue')
  },
  {
    path: 'trainee-selection',
    name: prop + 'trainee_selection',
    meta: { auth: true },
    component: () => import('../pages/trainee-selection/List.vue')
  },
  {
    path: 'allowance',
    name: prop + 'allowance_list',
    meta: { auth: true },
    component: () => import('../pages/allowance/List.vue')
  },
  {
    path: 'allowance-form',
    name: prop + 'allowance_form',
    meta: { auth: true },
    component: () => import('../pages/allowance/Form.vue')
  },
  {
    path: 'allowance-details',
    name: prop + 'allowance_details',
    meta: { auth: true },
    component: () => import('../pages/allowance/Details.vue')
  },
  {
    path: 'trainee-selection-form',
    name: prop + 'trainee_selection_form',
    meta: { auth: true },
    component: () => import('../pages/trainee-selection/Form.vue')
  },
  {
    path: 'manual-course-evaluation',
    name: prop + 'manual_course_evaluation',
    meta: { auth: true },
    component: () => import('../pages/manual-course-evaluation/List.vue')
  },
  {
    path: 'post-course-evaluation',
    name: prop + 'post_course_evaluation',
    meta: { auth: true },
    component: () => import('../pages/post-course-evaluation/List.vue')
  },
  {
    path: 'manual-course-evaluation-form',
    name: prop + 'manual_course_evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/manual-course-evaluation/Form.vue')
  },
  {
    path: 'post-course-evaluation-form',
    name: prop + 'post_course_evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/post-course-evaluation/Form.vue')
  },
  {
    path: 'manual-course-evaluation-details',
    name: prop + 'manual_course_evaluation_details',
    meta: { auth: true },
    component: () => import('../pages/manual-course-evaluation/Details.vue')
  },
  {
    path: 'post-course-evaluation-details',
    name: prop + 'post_course_evaluation_details',
    meta: { auth: true },
    component: () => import('../pages/post-course-evaluation/Details.vue')
  },
  {
    path: 'bill-payment',
    name: prop + 'bill_payment_list',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/List.vue')
  },
  {
    path: 'bill-payment-circular-wise',
    name: prop + 'bill_payment_circular_wise',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/circularWiseList.vue')
  },
  {
    path: 'bill-payment-details',
    name: prop + 'bill_payment_details',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/Details.vue')
  },
  {
    path: 'bill-payment-all-details',
    name: prop + 'bill_payment_all_details',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/AllDetails.vue')
  },
  {
    path: 'bill-payment/:status',
    name: prop + '.bill_payment_list.status',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/List.vue')
  },
  {
    path: 'other-bill-payment',
    name: prop + 'bill_payment_list',
    meta: { auth: true },
    component: () => import('../pages/other-bill-payment/List.vue')
  },
  {
    path: 'other-bill-payment/:status',
    name: prop + '.other_bill_payment_list.status',
    meta: { auth: true },
    component: () => import('../pages/other-bill-payment/List.vue')
  },
  {
    path: 'other-bill-payment-form',
    name: prop + 'other_bill_payment-form',
    meta: { auth: true },
    component: () => import('../pages/other-bill-payment/Form.vue')
  },
  {
    path: 'bill-payment-form',
    name: prop + 'bill_payment-form',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/Form.vue')
  },
  {
    path: 'trainee-id-card',
    name: prop + 'trainee_id_card',
    meta: { auth: true },
    component: () => import('../pages/trainee-id-card/List.vue')
  },
  {
    path: 'attendance',
    name: prop + 'attendance',
    meta: { auth: true },
    component: () => import('../pages/attendance/List.vue')
  },
  {
    path: 'attendance-details',
    name: prop + 'attendance_details',
    meta: { auth: true },
    component: () => import('../pages/attendance/AttendenceDetails.vue')
  },
  {
    path: 'attendance-form',
    name: prop + 'attendance_form',
    meta: { auth: true },
    component: () => import('../pages/attendance/Form.vue')
  },
  {
    path: 'evaluation',
    name: prop + 'evaluation',
    meta: { auth: true },
    component: () => import('../pages/evaluation/List.vue')
  },
  {
    path: 'evaluation-form',
    name: prop + 'evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/evaluation/Form.vue')
  },
  {
    path: 'evaluation-user',
    name: prop + 'evaluation_user',
    meta: { auth: true },
    component: () => import('../pages/evaluation/EvaluateUser.vue')
  },
  {
    path: 'course-evaluation',
    name: prop + 'course_evaluation',
    meta: { auth: true },
    component: () => import('../pages/course-evaluation/List.vue')
  },
  {
    path: 'course-evaluation-form',
    name: prop + 'course_evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/course-evaluation/Form.vue')
  },
  {
    path: 'disciplinary-action',
    name: prop + 'disciplinary_action',
    meta: { auth: true },
    component: () => import('../pages/disciplinary-action/List.vue')
  },
  {
    path: 'trainee-evaluation',
    name: prop + 'trainee_evaluation',
    meta: { auth: true },
    component: () => import('../pages/trainee-evaluation/List.vue')
  },
  {
    path: 'trainee-evaluation-form',
    name: prop + 'trainee_evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/trainee-evaluation/Form.vue')
  },
  {
    path: 'trainer-evaluation',
    name: prop + 'trainer_evaluation',
    meta: { auth: true },
    component: () => import('../pages/trainer-evaluation/List.vue')
  },
  {
    path: 'trainer-evaluation-details',
    name: prop + 'trainer_evaluation_details',
    meta: { auth: true },
    component: () => import('../pages/trainer-evaluation/Details.vue')
  },
  {
    path: 'other-training-honorarium-sheet',
    name: prop + 'other_training_honorarium_sheet',
    meta: { auth: true },
    component: () => import('../pages/other-training-honorium-sheet/List.vue')
  },
  {
    path: 'other-training-honorarium-sheet-details',
    name: prop + 'other_training_honorarium_sheet_details',
    meta: { auth: true },
    component: () => import('../pages/other-training-honorium-sheet/Details.vue')
  },
  {
    path: 'other-honorarium-form',
    name: prop + 'other_honorarium_form',
    meta: { auth: true },
    component: () => import('../pages/other-honorarium/Form.vue')
  },
  {
    path: 'other-honorarium-details',
    name: prop + 'other_honorarium_details',
    meta: { auth: true },
    component: () => import('../pages/other-honorarium/Details.vue')
  },
  {
    path: 'other-honorarium-list',
    name: prop + 'other_honorarium_list',
    meta: { auth: true },
    component: () => import('../pages/other-honorarium/List.vue')
  },
  {
    path: 'other-allowance-list',
    name: prop + 'other_allowance_list',
    meta: { auth: true },
    component: () => import('../pages/other-allowance/List.vue')
  },
  {
    path: 'other-allowance-form',
    name: prop + 'other_allowance_form',
    meta: { auth: true },
    component: () => import('../pages/other-allowance/Form.vue')
  },
  {
    path: 'certificate-generate-list',
    name: prop + 'certificate_generate_list',
    meta: { auth: true },
    component: () => import('../pages/certificate-generate/List.vue')
  },
  {
    path: 'certificate-list',
    name: prop + 'certificate_list',
    meta: { auth: true },
    component: () => import('../pages/certificate-list/List.vue')
  },
  {
    path: 'certificate-generate-form',
    name: prop + 'certificate_generate_form',
    meta: { auth: true },
    component: () => import('../pages/certificate-generate/Form.vue')
  },
  {
    path: 'circular-wise-certificate',
    name: prop + 'circular_wise_certificate',
    meta: { auth: true },
    component: () => import('../pages/all-certificate-list-pdf/List.vue')
  },
  {
    path: 'certificate-generate-details',
    name: prop + 'certificate_generate_details',
    meta: { auth: true },
    component: () => import('../pages/certificate-generate/Details.vue')
  },
  {
    path: 'certificate-generate-details-two',
    name: prop + 'certificate_generate_details_two',
    meta: { auth: true },
    component: () => import('../pages/certificate-generate/DetailsTwo.vue')
  },
  {
    path: 'certificate',
    name: prop + 'certificate',
    meta: { auth: true },
    component: () => import('../pages/certificate-list/certificateView.vue')
  },
  {
    path: 'badc-certificate',
    name: prop + 'badc_certificate',
    meta: { auth: true },
    component: () => import('../pages/certificate-list/BADCCertificateView.vue')
  },
  {
    path: 'bwmri-certificate',
    name: prop + 'bwmri_certificate',
    meta: { auth: true },
    component: () => import('../pages/certificate-list/bwmri/CertificateView.vue')
  },
  {
    path: 'other-allowance-details',
    name: prop + 'other_allowance_details',
    meta: { auth: true },
    component: () => import('../pages/other-allowance/Details.vue')
  },
  {
    path: 'trainer-wise-evaluation',
    name: prop + 'trainer_wise_evaluation',
    meta: { auth: true },
    component: () => import('../pages/trainer-wise-evaluation/List.vue')
  },
  {
    path: 'trainer-wise-evaluation-details',
    name: prop + 'trainer_wise_evaluation_details',
    meta: { auth: true },
    component: () => import('../pages/trainer-wise-evaluation/Details.vue')
  },
  {
    path: 'officer-material',
    name: prop + 'material_officer_list',
    meta: { auth: true },
    component: () => import('../pages/officer-material/List.vue')
  },
  {
    path: 'officer-material-details',
    name: prop + 'material_officer_details',
    meta: { auth: true },
    component: () => import('../pages/officer-material/Details.vue')
  },
  {
    path: 'other-material',
    name: prop + 'other_material_list',
    meta: { auth: true },
    component: () => import('../pages/other-material/List.vue')
  },
  {
    path: 'other-material-details',
    name: prop + 'other_material_details',
    meta: { auth: true },
    component: () => import('../pages/other-material/Details.vue')
  },
  {
    path: 'expertise-trainers',
    name: prop + 'expertise_trainers',
    meta: { auth: true },
    component: () => import('../pages/expertise-trainers/List.vue')
  },
  {
    path: 'expertise-trainers-form',
    name: prop + 'expertise_trainers_form',
    meta: { auth: true },
    component: () => import('../pages/expertise-trainers/Form.vue')
  },
  {
    path: 'trainee-approved',
    name: prop + 'trainee_approved',
    meta: { auth: true },
    component: () => import('../pages/trainee-approved/List.vue')
  },
  {
    path: 'training-course-evaluation',
    name: prop + 'training_course_evaluation',
    meta: { auth: true },
    component: () => import('../pages/training-course-evaluation/List.vue')
  },
  {
    path: 'training-course-evaluation-details',
    name: prop + 'training_course_evaluation_details',
    meta: { auth: true },
    component: () => import('../pages/training-course-evaluation/Details.vue')
  },
  {
    path: 'circular-wise-training-course-evaluation',
    name: prop + 'circular_wise_training_course_evaluation',
    meta: { auth: true },
    component: () => import('../pages/training-course-evaluation/CircularWiseList.vue')
  },
  {
    path: 'other-honorarium-sheet-list',
    name: prop + 'other_honorarium_sheet_list',
    meta: { auth: true },
    component: () => import('../pages/other-honorarium-sheet/List.vue')
  },
  {
    path: 'other-honorarium-sheet-details',
    name: prop + 'other_honorarium_sheet_details',
    meta: { auth: true },
    component: () => import('../pages/other-honorarium-sheet/Details.vue')
  },
  {
    path: 'trainee-rejected',
    name: prop + 'trainee_rejected',
    meta: { auth: true },
    component: () => import('../pages/trainee-rejected/List.vue')
  }
]

const routes = [
    {
      path: '/training-e-learning-service/tim',
      name: 'training_e_learning_service.tim',
      component: () => import('@/layouts/TrainingElearningLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_service.tim')
    }
]

export default routes
