import Custom from './custom'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

/**
 * This store will be used for all modules of this component
 */
const state = {
  commonObj: {
    hasDropdownLoaded: false,
    perPage: 10,
    dateFormat: 'dd/mm/YYYY',
    timeFormat: 'h:m',
    loading: false,
    listReload: false,
    regionList: [],
    loggedUserPrivilege: [],
    cottonNameList: [],
    cottonVaritiesList: [],
    zoneList: [],
    hatList: [],
    unitList: [],
    seasonList: [],
    campaignNameList: [],
    commodityTypeList: [],
    commodityGroupList: [],
    commoditySubGroupList: [],
    commodityNameList: [],
    marketList: [],
    alertPercentageList: [],
    divisionalOfficeList: [],
    campaignTypeList: Custom.campaignTypeList,
    ginnerGrowerList: [],
    yearList: Custom.yearList,
    weekList: Custom.weekList,
    timePreiodList: Custom.timePreiodList,
    priceEntryTypeList: Custom.priceEntryTypeList,
    priceTypeList: Custom.priceTypeList,
    leaseYearList: [],
    dayList: Custom.dayList,
    measurementUnitList: [],
    infrastructureList: [],
    communicationLinkageList: [],
    designationOfProductList: [],
    vehicleList: [],
    commentList: []
  },
  damData: {
    hasDropdownLoaded: false,
    divisionList: [],
    districtList: [],
    upazilaList: []
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
